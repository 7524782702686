<template>
  <div class="content-page web_cart">
    <!-- 导航 -->
    <el-card class="mb-3">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/webstore' }">Webstore</el-breadcrumb-item>
          <el-breadcrumb-item>Shopping Cart</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </el-card>
    <!-- 商品详情 -->
    <div class="background p-3">
      <div class="row font-title">
        <div class="col-4 pl-sm-4">Goods</div>
        <div class="col-8">
          <div class="row text-center">
            <div class="col-3">Unit-price</div>
            <div class="col-3">Quantity</div>
            <div class="col-3">Amount</div>
            <div class="col-3">Operation</div>
          </div>
        </div>
      </div>
      <div v-for="item of 2" :key="item">
        <div class="mt-3 font-title">
          <p class="pl-3 d-flex align-items-center">
            <input type="checkbox" />
            <i class="ri-store-2-line pl-3 pr-2" style="font-size: 20px;"></i>
            <span>Meiji Chocolate Cup</span>
          </p>
        </div>
        <el-card>
          <div class="row p-3" v-for="item of 2" :key="item">
            <div class="col-sm-4">
              <div class="row">
                <div class="col-1 pt-3"><input type="checkbox" /></div>
                <div class="col-3">
                  <img class="w-100" src="../../../assets/images/user/02.jpg" alt="" />
                </div>
                <div class="col-8 pl-sm-3">
                  <p class="font-title">Meiji Chocolate Cup</p>
                  <p>Size : S</p>
                </div>
              </div>
            </div>

            <div class="col-sm-8 s-card">
              <div class="row">
                <div class="col-3">
                  <h6 class="text-muted"><s>$20.00</s></h6>
                  <h6 class="font-title">${{ price }}</h6>
                </div>
                <div class="col-sm-3 pt-sm-1">
                  <button @click="minus" class="btn-small">-</button>
                  <input class="num" type="text" :value="number" />
                  <button @click="add" class="btn-small">+</button>
                </div>
                <div class="col-3 pt-sm-1 font-title">
                  <h6>${{ amount }}.00</h6>
                </div>
                <div class="col-sm-3 pt-sm-1">
                  <a style="color: #ef5959" href="javascript:;">Delete</a>
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <!-- 结算 -->
    <div class="f-card pl-3">
        <div class="d-flex align-items-center justify-content-between pl-3">
          <div class="font-title d-flex align-items-center">
            <input type="checkbox" />
            <span class="pl-3">select all</span>
            <div class="pl-5">
              <a style="color: #ef5959" href="javascript:;">Delete</a>
            </div>
          </div>
          <div class="font-title d-flex align-items-center flex-1">
            <h5 class="pr-3"><span class="text-muted">Total:</span>${{ total }}</h5>
            <button class="btn">Check out</button>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        price: 20.0,
        number: 1,
        amount: 20.0,
        total: 20.0,
      };
    },
    methods: {
      add() {
        this.number++;
        this.amount = this.number * this.price;
        this.total = this.amount + this.amount;
      },
      minus() {
        if (this.number > 1) {
          this.number--;
          this.amount = this.number * this.price;
          this.total = this.amount + this.amount;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .web_cart {
    margin-bottom: 80px;
    padding:90px 0 20px 0 !important;

    ::v-deep .el-card__body {
      padding: 0;
    }
  }

  .background {
    background-color: #FAFAFB;
  }

  .breadcrumb {
    height: 60px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    background-color: #fff;

    ::v-deep span.el-breadcrumb__inner.is-link {
      color: #50B5FF;
      font-weight: 500;
    }
  }

  .num {
    width: 30px;
    height: 30px;
    border: 1px solid #0aa1eb;
    color: #0aa1eb;
    margin: 0 3px;
    text-align: center;
  }

  .card-body {
    padding: 0;
  }

  .font-title {
    color: #333;
    font-weight: bold;
  }

  .btn-small {
    width: 30px;
    height: 30px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }

  .s-card {
    text-align: center;
    padding-top: 15px;
  }

  .b-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px 10px 0 10px;
  }

  .f-card {
    position: fixed;
    width: 1200px;
    bottom: 0;
    background-color: #FAFAFB;
  }

  .btn {
    width: 204px;
    height: 80px;
    color: #fff;
    background: #0aa1eb;
    border-radius: 0 !important;
  }
</style>